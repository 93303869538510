import { Card, CardProps, theme } from 'antd';

interface IProps extends CardProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  extra?: React.ReactNode;
}

export default function ContainerCard(props: IProps) {
  const { children, title, extra, ...rest } = props;
  const token = theme.useToken();

  return (
    <Card {...rest} bordered bodyStyle={{ padding: 16, height: '100%' }}>
      <div className="flex justify-between items-center mb-[8px]">
        <div className="text-base font-bold relative">
          <div
            className="w-1 h-full rounded-lg absolute top-0"
            style={{ backgroundColor: token.token.colorPrimary }}></div>
          <span className="ml-[16px]">{title}</span>
        </div>
        {extra}
      </div>
      {children}
    </Card>
  );
}
